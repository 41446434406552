
import {Component, Vue} from 'vue-property-decorator'
import {ApiResource} from "@/resources"
import BasePanel from '@/components/base/BasePanel.vue'
import prettyBytes from 'pretty-bytes'
import AsyncComputed from 'vue-async-computed-decorator'

@Component({components: {BasePanel}})
export default class AdminStoragePanel extends Vue {

  headers: any = [
      { text: 'Name', value: 'name' },
      { text: 'Size', align: 'center', value: 'size'},
  ]

  items : any = []

  
  @AsyncComputed()
  async storage() {
    var result = await new ApiResource("admin/storage").get(false)
    this.items.splice(0)
    this.items.push({name: "Media files", size: prettyBytes(result.data.media)})
    this.items.push({name: "Thumbnail files", size: prettyBytes(result.data.thumbnails)})
    this.items.push({name: "Database", size: prettyBytes(result.data.db)})
    
  }

}
